import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

const PrivacyStatement = inject("stores") (
    observer (
        class Contact extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    title: '',
                    content: ''
                }
            }

            componentDidMount = async (prevProps) => {
                const content = await this.storeContent.getContentDialog(this.props.id);
                this.setState({
                    title: content.title.rendered,
                    content: content.content.rendered
                })
            }

            render() {
                return (
                    <div className="content--privacy">
                        {this.state.title && 
                            <h2 className="page-content__title" dangerouslySetInnerHTML={{ __html: this.state.title}} />
                        }
                        {this.state.content && 
                            <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.state.content}} />
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(PrivacyStatement);