import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';

class StoreUi {
    app_interface = { 
        lang: '',
        lang_id: 0,
        height_footer: 0,
        isDialogContentOpen: false
    } 
    menu_mobile = false;
    content_component = ''

    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
    }

    setContentComponent(comp) {
        this.content_component = comp;
    }
    
    setHeightFooter(num) {
        this.app_interface.height_footer = num;
    }

    setMobileMenu() {
        this.menu_mobile = !this.menu_mobile;
    }
}

decorate(StoreUi, {
    app_interface: observable,
    content_component: observable,
    menu_mobile: observable,
    closeDialogContent: action,
    openDialogContent: action,
    setContentComponent: action,
    setHeightFooter: action,
    setMobileMenu: action
})

export default StoreUi;