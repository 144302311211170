import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import {
    isBrowser,
    isMobile
} from "react-device-detect";
import ReactGA from 'react-ga';

import './assets/scss/one4one.scss';
import Template from './templates/template';

import AppStart from './pages/start';
import ContentPage from './pages/content';
import Dialog from './components/dialogs/dialog';

const DEFAULT_CONFIG = {
    trackingId: 'UA-179383998-1',
    debug: true,
    gaOptions: {
    cookieDomain: 'none'
    }
};

const App = inject("stores") (   
    observer (
        class App extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    reactGaInitialised: false,
                    configs: [DEFAULT_CONFIG]
                };

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                this.initReactGA();
            }

            initReactGA = () => {
                const { configs } = this.state;
                ReactGA.initialize(configs);
            };

            render() {
                return (
                    <React.Fragment>
                        <div className={"app-content " + (isMobile ? "app-content--mobile" : "app-content--desktop")}>
                            <Template>
                                <Switch>
                                    <Route exact path='/' component={AppStart}/>
                                    <Route path='/content/:id?' component={ContentPage}/>
                                </Switch>
                            </Template>
                        </div>

                        {this.storeUi.app_interface.isDialogContentOpen &&
                            <Dialog
                                show = {this.storeUi.app_interface.isDialogContentOpen}
                                onClose = {() => this.storeUi.closeDialogContent()}
                                buttons = {
                                    [{
                                        text: "Sluit venster",
                                        onClick: () => {this.storeUi.closeDialogContent()},
                                        btnClass: "--primary"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </Dialog>
                        }
                    </React.Fragment>
                );
            }
        }
    )
)

export default App;
