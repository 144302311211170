import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import * as ui from '../utilities/ui';

import Ankeiler1 from '../components/blocks/ankeiler1';
import Ankeiler2 from '../components/blocks/ankeiler2';

const ContentPage = inject("stores") (
    observer (
        class ContentPage extends Component {

            constructor(props) {
                super(props);
                this.state = {
                    
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async () => {
                ui.handleScroll('top');
                const id = this.props.match.params.id;
                (async () => await this.storeContent.getContentPage(id))();
                (async () => await this.storeContent.getCustomFields(id))();
            }

            componentDidUpdate = async (prevProps) => {
                ui.handleScroll('top');
                if (prevProps.match.params.id !== this.props.match.params.id) {
                    (async () => await this.storeContent.getContentPage(this.props.match.params.id))();
                    (async () => await this.storeContent.getCustomFields(this.props.match.params.id))();
                }
            }

            render() {
                return (
                    <div className="page-content">
                        {this.storeContent.current_page.title && 
                            <h2 className="page-content__title" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.title.rendered}} />
                        }
                        {this.storeContent.current_page.content && 
                            <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.content.rendered}} />
                        }

                        <Ankeiler1 
                        
                        />

                        <Ankeiler2 
                        
                        />
                    </div>
                )
            }
        }
    )
)

export default ContentPage;

