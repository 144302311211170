import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

const Contact = inject("stores") (
    observer (
        class Contact extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                // /wp-json/acf/v3/pages/{id}/{field-name}
            }

            render() {
                return (
                    <div className="contact theme--light">
                        <div className="page-content">
                            <div className="page-content__contact">
                                <div className="page-content__contact--content">
                                    <div className="page-content__title">
                                        <h2>Contact</h2>
                                    </div>
                                    
                                    <div className="page-content__text">
                                        <ul className="page-content__list">
                                            <li>Marielle Ruiters</li>
                                            <li>
                                                <span className="page-content__list--label">e-mail</span>
                                                <span className="page-content__list--value">info@one4onecoaching.nl</span>
                                            </li>
                                            <li>
                                                <span className="page-content__list--label">telefoon</span>
                                                <span className="page-content__list--value"> 06 2120 0623</span>
                                            </li>
                                            <li>
                                                <span className="page-content__list--label">KvK-nummer</span>
                                                <span className="page-content__list--value">57189269</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Contact);