import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import sizeMe from 'react-sizeme';

import NavigationFooter from './navigation-footer';
import PrivacyStatement from './blocks/privacy-statement';
import DialogContent from './dialogs/dialog-content';

const Footer = inject("stores") (
    observer (
        class Footer extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidUpdate = () => {
                const { width, height } = this.props.size;
                this.storeUi.setHeightFooter(this.props.size.height);
            }

            openDialogPrivacy = (e) => {
                e.preventDefault();
                this.storeUi.setContentComponent(
                    <DialogContent
                        className = "content-scrollable"
                    >
                        <PrivacyStatement 
                            id = {24}
                        />
                    </DialogContent>
                )
                this.storeUi.openDialogContent();
            }

            render() {
                return (
                    <div className="wrapper-footer">
                        <div className="footer">
                            <div className="footer__content">
                                <NavigationFooter />

                                <div className="footer__privacy">
                                    Bij mijn werk als coach en als relatietherapeut ga ik zorgvuldig om met persoonsgegevens.<br/>Hoe ik dat doe lees je in de <span className="--link --decorated" onClick={(e) => this.openDialogPrivacy(e)}>privacyverklaring</span>.
                                </div>
                                
                                <div className="footer__copyright">
                                    &copy; 2020  One4one Coaching
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(sizeMe({ monitorHeight: true })(Footer));