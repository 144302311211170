import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import PrivacyStatement from './privacy-statement';
import DialogContent from '../dialogs/dialog-content';

const Ankeiler1 = inject("stores") (
    observer (
        class Ankeiler1 extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            openDialogPrivacy = () => {
                this.storeUi.setContentComponent(
                    <DialogContent
                        className = "content-scrollable"
                    >
                        <PrivacyStatement 
                            id = {24}
                        />
                    </DialogContent>
                )
                this.storeUi.openDialogContent();
            }

            render() {
                return (
                    <div className="ankeiler ankeiler--forward theme--light">
                        <div className="ankeiler__text">{this.storeContent.current_page.FocusText1}</div>
                        <div className={"ankeiler__link --" + this.storeContent.current_page.Link1Direction}>
                            {this.storeContent.current_page.Link1Extern && this.storeContent.current_page.Link1Extern === 'extern' &&
                               <a href={this.storeContent.current_page.FocusLink1} target="blank">
                                    <div className={"ankeiler__link--icon --" + this.storeContent.current_page.Link1Direction}></div>
                                </a>
                            }
                            {this.storeContent.current_page.Link1Popup && this.storeContent.current_page.Link1Popup === 'popup' &&
                                <span className="--link --decorated" onClick={() => this.openDialogPrivacy()}>
                                    <div className={"ankeiler__link--icon --" + this.storeContent.current_page.Link1Direction}></div>
                                </span>
                            }
                            {this.storeContent.current_page.Link1Extern !== 'extern' && this.storeContent.current_page.Link1Popup !== 'popup' &&
                                <Link
                                    to = {"/content/" + this.storeContent.current_page.FocusLink1}
                                >
                                    <div className={"ankeiler__link--icon --" + this.storeContent.current_page.Link1Direction}></div>
                                </Link>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Ankeiler1);