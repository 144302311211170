import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import {
    isBrowser,
    isMobile
} from "react-device-detect";


const NavigationFooter = inject("stores") (
    observer (
        class NavigationFooter extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = ({
                    menu_1: [],
                    menu_2: [],
                    menu_3: []
                })
            }

            componentDidMount = async() => {
                const id_menu_1 = 2;
                let menu_1 = await this.storeContent.getMenu(id_menu_1);

                const id_menu_2 = 3;
                let menu_2 = await this.storeContent.getMenu(id_menu_2);

                const id_menu_3 = 7;
                let menu_3 = await this.storeContent.getMenu(id_menu_3);

                this.setState({
                    menu_1: menu_1.items,
                    menu_2: menu_2.items,
                    menu_3: menu_3.items
                })
            }

            handleClick = () => {
                if (isMobile) {
                    this.storeUi.setMobileMenu();
                }
            }

            render() {
                return (
                    <div className="footer">
                        <div className="footer__wrapper-navigation">
                            <div className="navigation">
                                <ul className="navigation__list">
                                    {this.state.menu_1.map((menuitem) => (
                                        <li className="navigation__listitem">
                                            <Link 
                                                to={"/content/" + menuitem.object_id}
                                                onClick = {(e) => this.handleClick()}
                                            >
                                                {menuitem.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="navigation">
                                <ul className="navigation__list">
                                    {this.state.menu_2.map((menuitem) => (
                                        <li className="navigation__listitem">
                                        <Link 
                                            to={"/content/" + menuitem.object_id}
                                            onClick = {(e) => this.handleClick()}
                                        >
                                            {menuitem.title}
                                        </Link>
                                    </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="navigation">
                                <ul className="navigation__list">
                                    {this.state.menu_3.map((menuitem) => (
                                        <li className="navigation__listitem">
                                        <Link 
                                            to={"/content/" + menuitem.object_id}
                                            onClick = {(e) => this.handleClick()}
                                        >
                                            {menuitem.title}
                                        </Link>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationFooter);