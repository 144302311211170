import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import NavigationMain from './navigation-main';
import NavigationTrigger from './navigation-trigger';
import logo_img from '../assets/images/branding/logo-o4o-white-print.png';

const Header = inject("stores") (
    observer (
        class Header extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    <div className="wrapper-header">
                        <div className="content content--header">
                            <Link to="/">
                                <img src={logo_img} className="branding__logo--img" />
                            </Link>
                            <NavigationMain />
                            <NavigationTrigger />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Header);