import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css'

import * as ui from '../utilities/ui';

import Ankeiler1 from '../components/blocks/ankeiler1';
import Ankeiler2 from '../components/blocks/ankeiler2';

const AppStart = inject("stores") (
    observer (
        class AppStart extends Component {

            constructor(props) {
                super(props);
                this.state = {
                    redirectPage: false,
                    content: {},
                    page_id: 0,
                    slides: [{
                        text: "Ik ben verliefd<br/>...<br/>maar niet op mijn partner.<br/>Wat nu?"
                    },{
                        text: "Eigenlijk heb ik niets klagen<br/>...<br/>toch voel ik me niet happy"
                    },{
                        text: "Ik had het me nog zo voorgenomen<br/>...<br/>maar nu hebben we alweer ruzie"
                    }]
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async () => {
                ui.handleScroll('top');
                const params = queryString.parse(this.props.location.search);
                if (params.page) {
                    this.redirectParams(params);
                } else {
                    const id = 58;
                    (async () => await this.storeContent.getContentPage(id))(); 
                }
            }

            componentDidUpdate = async (prevProps) => {
            }

            redirectParams(params) {
                if (params.page) {
                    this.setState({
                        redirectPage: true,
                        page: params.page
                    });
                }
            }

            render() {
                if (this.state.redirectPage) {
                    return <Redirect 
                        push to={"/content/" + this.state.page} 
                    />;
                }

                return (
                    <div className="page-content">
                        <div className="carousel--quotes">
                            <Swiper 
                                ref={(c) => { this.reactSwiper = c }}
                                swiperOptions={{
                                    speed: 800,
                                    slidesPerView: 1,
                                    autoplay: {
                                        delay: 4000,
                                        disableOnInteraction: false
                                    },
                                    autoHeight: false,
                                }}
                                {...this.state.options}   
                            >
                                {this.state.slides.map((quote, i) => (
                                    <Slide key={i}>
                                        <div className="carousel__slide">
                                            <span dangerouslySetInnerHTML={{ __html: this.state.slides[i].text}} />
                                        </div>
                                    </Slide>
                                ))}
                            </Swiper>
                        </div>
                        
                        <div className="page-content--centered">
                            {this.storeContent.current_page.content && 
                                <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.content.rendered}} />
                            }
                        </div>

                        <Ankeiler1 
                        
                        />

                        <Ankeiler2 
                        
                        />
                    </div>
                )
            }
        }
    )
)

export default AppStart;

